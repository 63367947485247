import styled from 'styled-components'

import breakpoints from '../../data/breakpoints'

export const Container = styled.div`
  margin: 0 auto;

  @media (min-width: ${breakpoints.sm}) {
    max-width: ${breakpoints.sm};
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: ${breakpoints.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${breakpoints.lg};
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: ${breakpoints.xl};
  }
`

export const PostContainer = styled(Container)`
  padding-top: 63px;

  @media (min-width: ${breakpoints.md}) {
    max-width: ${breakpoints.sm};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${breakpoints.sm};
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: ${breakpoints.sm};
  }

  p {
    margin-bottom: 32px;
    font-size: 21px;
    line-height: 24px;
    padding: 8px 16px;
  }

  .feature-container {
    p {
      font-size: 24px;
      line-height: 30px;
    }
  }
`

// used for adding space at the bottom of normal pages (vs fullscreen)
export const PageWrapper = styled.div`
  margin-bottom: 84px;
`
