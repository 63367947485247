import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import Button from 'react-md/lib/Buttons/Button'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import Drawer from 'react-md/lib/Drawers/Drawer'
import Toolbar from 'react-md/lib/Toolbars/Toolbar'

import colors from '../data/color-pallette'
import { Container } from './common/container'
import { Title } from './common/title'
import breakpoints from '../data/breakpoints'

import logoSVG from '../../static/images/logo-white.svg'

const HeaderWrapper = styled.header`
  padding: 0 16px;
  background: ${colors.secondary};

  span.spacer {
    flex: 1 1 auto;
  }
`

const TitleLink = styled(Link)`
  text-decoration: none;
  font-family: 'Arvo';
  font-weight: bold;
  color: ${colors.heading};
  margin: 0;
  white-space: normal;
  display: flex;
  align-items: center;
  font-size: 32px;
  width: 290px;
  color: ${colors.white};
  text-decoration: none;

  img {
    margin-right: 8px;
    max-height: 42px;
    width: 42px;
  }
`

const HeaderContainer = styled(Container)`
  display: flex;
  height: 64px;

  ul {
    display: none;
    width: 100%;
    margin: 0;
    align-items: center;
    padding-left: 0;
    margin-left: 42px;

    @media (min-width: ${breakpoints.sm}) {
      display: flex;
    }

    li {
      list-style: none;
      font-weight: 600;
      font-size: 20px;
      a {
        color: ${colors.white};
        text-decoration: none;
        padding: 10px 15px;
        height: 100%;

        &.active {
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
`

const MenuButton = styled(Button)`
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }

  .md-icon {
    color: #fff;
    font-size: 40px;
  }
`
const CustomDrawer = styled(Drawer)`
  background: ${colors.secondary};

  .md-toolbar--action-right {
    .md-btn {
      padding: 0;
    }
    .md-icon {
      color: #fff;
      font-size: 40px;
    }
  }

  ul {
    li {
      padding: 10px 0;
      list-style: none;
      a {
        color: ${colors.white};
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
`

const Header = ({ siteTitle, dashboardUrl }) => {
  const [drawerVisible, setDrawerVisible] = useState(false)

  const closeBtn = (
    <Button
      icon
      onClick={() => {
        setDrawerVisible(!drawerVisible)
      }}
    >
      close
    </Button>
  )
  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <TitleLink to="/">
            <img src={logoSVG} />
            {siteTitle}
          </TitleLink>
          <ul>
            <li>
              <Link activeClassName="active" to="/pricing">
                Pricing
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/features">
                Features
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/news">
                News
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/help">
                Help
              </Link>
            </li>
            <span className="spacer"></span>
            <li>
              <a href={`${dashboardUrl}/login`}>Sign-up</a>
            </li>
          </ul>

          <span className="spacer"></span>
          <MenuButton
            onClick={() => {
              setDrawerVisible(!drawerVisible)
            }}
          >
            <FontIcon>menu</FontIcon>
          </MenuButton>
        </HeaderContainer>
      </HeaderWrapper>
      <CustomDrawer
        id="simple-drawer-example"
        type={Drawer.DrawerTypes.TEMPORARY}
        visible={drawerVisible}
        onVisibilityChange={setDrawerVisible}
        position="left"
        header={
          <Toolbar
            actions={closeBtn}
            className="md-divider-border md-divider-border--bottom"
          >
            <TitleLink to="/">{siteTitle}</TitleLink>
          </Toolbar>
        }
      >
        <ul>
          <li>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li>
            <Link to="/features">Features</Link>
          </li>
          <li>
            <Link to="/help">Help</Link>
          </li>
          <span className="spacer"></span>
          <li>
            <a href={`${dashboardUrl}/login`}>Sign-up</a>
          </li>
        </ul>
      </CustomDrawer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
