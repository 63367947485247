import styled from 'styled-components'

import { Container } from './container'

import colors from '../../data/color-pallette'

export const HeroSection = styled(Container)`
  height: 320px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-shadow: 0 0 5px #000;

  h1 {
    font-size: 54px;
    max-width: 100%;
  }

  h1,
  h2 {
    color: #fff;
  }

  p {
    color: ${colors.white};
    font-size: 20px;
    max-width: 400px;
  }

  a {
    text-shadow: none;
  }
`

export const FullScreenHeroSection = styled(HeroSection)`
  height: auto;
  min-height: calc(100vh - 106.5px);
  width: 100vw;
  overflow-y: auto;
  padding-top: 64px;
  padding-bottom: 42px;

  @media (min-width: 0px) {
    max-width: 100vw;
  }

  p {
    text-align: left;
    margin-bottom: 10px;
  }
`

export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      hiRes: sqip(
        numberOfPrimitives: $SQIP_HERO_SHAPE_COUNT
        blur: 0
        width: 256
        mode: 1
      ) {
        dataURI
      }
      lowRes: sqip(
        numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
        blur: 0
        width: 256
        mode: 1
      ) {
        dataURI
      }
    }
  }
`
