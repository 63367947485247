const colors = {
  error: '#9e1b2a',
  success: '#72962b',
  primary: '#274685',
  secondary: '#316ed0',
  darkGrey: '#23242c',
  mediumGrey: '#373a4b',
  lightGrey: '#7d8a9c',
  white: '#ffffff',
}

export default {
  ...colors,
  text: colors.darkGrey,
  heading: colors.darkGrey,
  contentBackground: colors.white,
  pageBackground: colors.lightGrey,
  borderColor: colors.mediumGrey,
}
