/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Header from './header'
import { Container } from './common/container'
import colorPallette from '../data/color-pallette'

const Footer = styled.footer`
  background: ${colorPallette.primary};
  color: ${colorPallette.white};
  text-align: center;
  padding: 10px;
`

const PageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1 1 auto;
  }
`

const Layout = ({ children }) => {
  const {
    site: {
      siteMetadata: { dashboardUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            dashboardUrl
          }
        }
      }
    `
  )

  return (
    <>
      <Helmet>
        <script
          async
          src="https://analytics.streamparrot.com/tracker.js"
        ></script>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/images/icon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/images/icon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/images/icon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/images/icon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/images/icon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/images/icon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/images/icon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/icon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/icon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/images/icon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/icon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/images/icon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/icon/favicon-16x16.png"
        />
        <link rel="manifest" href="/images/icon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/images/icon/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="/fonts/arvo/stylesheet.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/fonts/lato/stylesheet.css"
        />
      </Helmet>
      <PageLayoutWrapper className="page-layout-wrapper">
        <Header siteTitle="StreamParrot" dashboardUrl={dashboardUrl} />
        <main>{children}</main>
        <Footer>
          <Container>© {new Date().getFullYear()}, Appropont LLC</Container>
        </Footer>
      </PageLayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
