import Card from 'react-md/lib/Cards/Card'
import styled from 'styled-components'
import colors from '../../data/color-pallette'

export const CustomCard = styled(Card)`
  transition: all 300ms;

  .md-card-title {
    padding-bottom: 0;
  }

  h3 {
    white-space: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    margin-top: 8px;
  }

  &:hover {
    transform: scale3d(1.05, 1.05, 1.05);
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.3);
  }

  a {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.secondary};
      backface-visibility: hidden;
    }

    p,
    span {
      backface-visibility: hidden;
    }
  }

  span {
    backface-visibility: hidden;
  }
`
