import React from 'react'
import { Link, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { Container, PageWrapper } from '../components/common/container'
import { Title } from '../components/common/title'
import { HeroSection } from '../components/common/hero-section'
import { CustomCard } from '../components/common/card'

import colors from '../data/color-pallette'

import Cell from 'react-md/lib/Grids/Cell'
import Grid from 'react-md/lib/Grids/Grid'
import Button from 'react-md/lib/Buttons/Button'

import breakpoints from '../data/breakpoints'

// https://thenounproject.com/search/?q=change&i=2277883

const BaseCard = styled(CustomCard)`
  h3 {
    white-space: normal;
  }
`

const CustomHeroSection = styled(HeroSection)`
  height: 550px;

  p {
    padding: 0 60px;
  }
`

const HeroTitle = styled(Title)`
  color: ${colors.white};
  margin-bottom: 12px;
  text-align: center;
`

const SectionTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 32px;
  text-decoration: none;
`

const FeaturesContainer = styled(Container)`
  text-align: center;
  margin-top: 40px;
`

const FeatureGrid = styled(Grid)`
  justify-content: space-around;
  padding: 0;

  @media (max-width: 991px) and (min-width: 600px) {
    .md-cell--3 {
      width: calc(50% - 16px);
    }
  }

  @media (max-width: 599px) {
    .md-cell--3 {
      width: calc(100% - 48px);
    }
  }
`

const FeatureCell = styled(Cell)`
  a {
    text-decoration: none;
  }
`

const FeatureCard = styled(BaseCard)`
  padding: 20px;
  height: 100%;

  img,
  svg {
    height: auto;
    fill: ${colors.secondary};
  }

  h3 {
    height: 2.5em;
    color: ${colors.secondary};
  }

  p {
    text-align: left;
  }
`

const FeaturedCardImage = styled(Img)`
  min-height: 150px;
  height: auto;
`

const ExtrasContainer = styled(Container)`
  margin-top: 40px;
`

const ExtrasGrid = styled(Grid)`
  justify-content: center;

  .md-cell--12-tablet.md-cell--12-tablet {
    width: calc(100%);
  }

  h2 {
    margin-bottom: 40px;
  }

  @media (min-width: ${breakpoints.sm}) {
    .md-cell--6 {
      width: 50%;
      margin: 0;
    }
  }
  @media (max-width: 839px) and (min-width: 600px) {
    .md-cell--6 {
      width: calc(100% - 16px);
    }
  }
`

const NewsContainer = styled(Container)`
  text-align: center;
  padding: 8px;
  margin: 0;

  @media (min-width: ${breakpoints.sm}) {
    padding: 8px 16px 8px 8px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 8px 52px;
  }
`

const NewsCard = styled(BaseCard)`
  margin-top: 28px;
  text-align: left;
  h3 {
    justify-content: left;
  }

  a {
    text-decoration: none;
  }

  .text-section {
    padding: 8px 24px;
  }
`

const PaddinglessGrid = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
`

const PaddinglessCell = styled.div`
  margin: 0;
  padding: 0;

  &.image-cell {
    width: 25%;
  }

  &.text-cell {
    width: 75%;
    padding-top: 16px;
    padding-bottom: 8px;
  }
`

const NewsCardImage = styled(Img)`
  min-height: 100px;
  height: 100%;
`

const HelpContainer = styled(Container)`
  text-align: center;
  padding: 8px;
  margin: 0;

  @media (min-width: ${breakpoints.sm}) {
    border-left: thin solid #ddd;
  }

  @media (min-width: ${breakpoints.sm}) {
    padding: 8px 8px 8px 16px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 8px 52px;
  }
`

const HelpCard = styled(BaseCard)`
  margin-top: 28px;
  text-align: left;

  a {
    text-decoration: none;
  }

  .text-wrapper {
    padding: 8px 24px;

    h3 {
      display: block;
      text-align: left;
    }
  }
`
const HelpImage = styled(Img)`
  min-height: 100px;
  height: 100%;

  @media (min-width: ${breakpoints.sm}) {
    min-height: 100px;
  }
`

const IndexPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { dashboardUrl },
      },
      heroImage,
      changeIconsImage,
      themingImage,
      bttvImage,
      arrowIcon,
      newsPosts,
      gettingStarted,
      features,
      knowledgeBaseImage,
    },
  } = props

  return (
    <Layout>
      <SEO title="StreamParrot - The best branding available for your chat overlay" />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: heroImage.childImageSharp.lowRes.dataURI,
              src: heroImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <CustomHeroSection>
              <HeroTitle>Powerful Branding</HeroTitle>
              <p>Curate the ideal chat overlay experience for your viewers</p>
              <Button
                raised
                secondary
                href={`${dashboardUrl}/login`}
                iconBefore={false}
                iconEl={<img src={`./images/arrow-right.svg`} />}
              >
                Find out more about the Beta!
              </Button>
            </CustomHeroSection>
          </BackgroundImage>
        </div>
        <FeaturesContainer>
          <Link to="/features">
            <SectionTitle as="h2">Features</SectionTitle>
          </Link>
          <FeatureGrid>
            {features.nodes.map(feature => (
              <FeatureCell size={3}>
                <Link to={feature.frontmatter.path}>
                  <FeatureCard>
                    <FeaturedCardImage
                      alt={feature.frontmatter.title}
                      fluid={{
                        base64:
                          feature.frontmatter.featuredImage.childImageSharp.sqip
                            .dataURI,
                      }}
                    />
                    <h3>{feature.frontmatter.title}</h3>
                    <p>{feature.frontmatter.summary}</p>
                  </FeatureCard>
                </Link>
              </FeatureCell>
            ))}
          </FeatureGrid>
        </FeaturesContainer>
        <ExtrasContainer noSpacing={true}>
          <ExtrasGrid>
            <Cell size={6} mobileSize={12}>
              <NewsContainer>
                <Link to="/news">
                  <SectionTitle as="h2">News</SectionTitle>
                </Link>
                {newsPosts.edges.map(
                  ({
                    node: {
                      frontmatter: {
                        postType,
                        title,
                        summary,
                        path,
                        featuredImage,
                      },
                    },
                  }) => {
                    return (
                      <NewsCard>
                        <Link to={path}>
                          <PaddinglessGrid>
                            <PaddinglessCell size={3} className="image-cell">
                              <NewsCardImage
                                alt={title}
                                fluid={{
                                  base64:
                                    featuredImage.childImageSharp.sqip.dataURI,
                                }}
                              />
                            </PaddinglessCell>
                            <PaddinglessCell size={9} className="text-cell">
                              <div class="text-section">
                                <h3>{title}</h3>
                                <p>{summary}</p>
                              </div>
                            </PaddinglessCell>
                          </PaddinglessGrid>
                        </Link>
                      </NewsCard>
                    )
                  }
                )}
              </NewsContainer>
            </Cell>
            <Cell size={6} mobileSize={12}>
              <HelpContainer>
                <Link to="/help">
                  <SectionTitle as="h2">Help</SectionTitle>
                </Link>
                <HelpCard>
                  <Link to={gettingStarted.frontmatter.path}>
                    <PaddinglessGrid>
                      <PaddinglessCell
                        size={3}
                        phoneSize={3}
                        className="image-cell"
                      >
                        <HelpImage
                          alt={gettingStarted.frontmatter.title}
                          fluid={{
                            base64:
                              gettingStarted.frontmatter.featuredImage
                                .childImageSharp.sqip.dataURI,
                          }}
                        />
                      </PaddinglessCell>
                      <PaddinglessCell
                        size={9}
                        phoneSize={9}
                        className="text-cell"
                      >
                        <div className="text-wrapper">
                          <h3>{gettingStarted.frontmatter.title}</h3>
                          <p>{gettingStarted.frontmatter.summary}</p>
                        </div>
                      </PaddinglessCell>
                    </PaddinglessGrid>
                  </Link>
                </HelpCard>
                <HelpCard>
                  <Link to="/help/kb">
                    <PaddinglessGrid>
                      <PaddinglessCell size={3} className="image-cell">
                        <HelpImage
                          alt="Knowledge Base"
                          fluid={{
                            base64:
                              knowledgeBaseImage.childImageSharp.sqip.dataURI,
                          }}
                        />
                      </PaddinglessCell>
                      <PaddinglessCell size={9} className="text-cell">
                        <div className="text-wrapper">
                          <h3>Knowledge Base</h3>
                          <p>
                            Get answers to Frequently Asked Questions and more.
                          </p>
                        </div>
                      </PaddinglessCell>
                    </PaddinglessGrid>
                  </Link>
                </HelpCard>
              </HelpContainer>
            </Cell>
          </ExtrasGrid>
        </ExtrasContainer>
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    site {
      siteMetadata {
        dashboardUrl
      }
    }
    heroImage: file(relativePath: { regex: "/parrot-portrait.jpeg/" }) {
      ...HeroImage
    }
    changeIconsImage: file(relativePath: { regex: "/change-icons.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    themingImage: file(relativePath: { regex: "/theming.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    bttvImage: file(relativePath: { regex: "/bttv-ffz.jpeg/" }) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
    newsPosts: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "news" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            path
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            featuredImage {
              childImageSharp {
                sqip(
                  numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                  blur: 0
                  width: 256
                  mode: 1
                ) {
                  dataURI
                }
              }
            }
          }
        }
      }
    }
    gettingStarted: markdownRemark(
      frontmatter: { path: { eq: "/help/getting-started" } }
    ) {
      html
      frontmatter {
        postType
        path
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        featuredImage {
          childImageSharp {
            sqip(
              numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
              blur: 0
              width: 256
              mode: 1
            ) {
              dataURI
            }
          }
        }
      }
    }
    features: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "feature" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 4
    ) {
      nodes {
        html
        frontmatter {
          postType
          path
          title
          date(formatString: "MMMM DD, YYYY")
          summary
          featuredImage {
            childImageSharp {
              sqip(
                numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                blur: 0
                width: 256
                mode: 1
              ) {
                dataURI
              }
            }
          }
        }
      }
    }

    knowledgeBaseImage: file(
      relativePath: { regex: "/parrot-portrait.jpeg/" }
    ) {
      childImageSharp {
        sqip(
          numberOfPrimitives: $SQIP_HERO_SHAPE_COUNT
          blur: 0
          width: 256
          mode: 1
        ) {
          dataURI
        }
      }
    }
  }
`
